:root {
  /* Colors */
  --primary-color: #ED46A0;
  --primary-color-light: #BD4384;
  --primary-color-dark: #BD2677;
  --secondary-color: #ffa701;
  --secondary-color-light: #4A1E93;
  --secondary-color-dark: #3C0893;
  --tertiary-color: #ED46A0;
  --tertiary-color-light: #C65D29;
  --tertiary-color-dark: #C6480B;

  /* Old Colors for reference */
  /*--secondary-color: #F8C000ff;390099*/
  /*--tertiary-color: #FFA701ff;ff5400*/

  /* Fonts */
  --font-family-primary: 'Montserrat', sans-serif;
  --font-family-secondary: 'Barlow Condensed', sans-serif;

  /* Common font sizes */
  --font-size-large: 40px;
  --font-size-medium: 25px;
  --font-size-small: 20px;
  --font-size-xsmall: 15px;

  /* Common spacing units */
  --spacing-small: 10px;
  --spacing-medium: 50px;
  --spacing-large: 100px;
}

body {
  font-family: var(--font-family-primary);
  font-weight: 500;
  color: #494F55;
}

/* Headings */
.heading {
  font-family: var(--font-family-secondary);
  color: var(--secondary-color);
  font-size: var(--font-size-large);
  font-weight: bold;
}

/* Testimonials */
.testimonial {
  padding: 20px;
  margin-bottom: 20px;
  font-size: 1rem; /* Base font size */
  line-height: 1.5;
}

.testimonialFont {
  font-family: var(--font-family-primary);
  font-size: 18px;
}

.testimonialAuthor {
  font-family: var(--font-family-primary);
  font-size: 18px;
}

.testimonialHighlights {
  color: var(--primary-color);
  font-weight: 600;
}

.testimonialPic {
  height: 275px;
  width: 275px;
  border-radius: 50%;
  background-position: top;
  background-repeat: no-repeat;
  object-fit: cover;
}

.testimonialImage {
  /*width: 300px;
  height: 300px;*/
  width: 100%;
  height: auto;
  border-radius: 50%;
  object-fit: cover;
}

/* Common form width */
.formwidth {
  width: 500px;
}

/* Font styling for forms */
.formFont {
  font-family: var(--font-family-primary);
}

/* Image positioning */
.picOneAbout {
  left: 30vh;
  margin-bottom: 50px;
}

.picTwoAbout {
  left: 5vh;
  top: 30vh;
}

/* Home opening and centered title */
.home-opening {
  width: 100%;
  margin: 0;
  padding: 20px;
  background-color: #f5f5f5; /* Example color */
  position: relative;
  text-align: center;
}

.home-opening-image {
  width: 100%;
  height: auto;
  display: block; /* Ensures the image takes the full width */
}

.centered-title {
  /* Existing styles */
  text-align: left;
  font-family: var(--font-family-primary);
  font-size: 2.5rem;
  margin-top: 10px;
  margin-bottom: 10px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);

  /* Color and Background */
  color: #FFFFFF;
  background-color: var(--primary-color);
  padding: 0.5rem 1rem;
  border-radius: 10px;

  /* Positioning */
  position: absolute;
  top: 7.5%;
  left: 25%;
  transform: translate(-25%,-10%);
}



/* Contact form heading */
.contactFormHeading {
font-family: var(--font-family-secondary);
color: var(--secondary-color);
font-size: var(--font-size-large);
font-weight: bold;
}

/* Image styles */
.servicesImage {
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  object-fit: cover;
  position: relative;
  zoom: 25%;
}

/* Services image sizing */
.servicesPhotos {
  height: 450px;
  width: 300px;
}

.service-column-border {
  box-sizing: border-box;
  border-right: 2px solid pink;
}

/* About page image positioning */
.aboutDiv {
  height: 300px;
  background-color: var(--primary-color-light);
}

.aboutImageDiv {
  height: 350px;
  position: relative;
}

.aboutHomeHeading {
  color: var(--secondary-color);
  font-size: 40px;
  font-weight: bold;
  font-family: var(--font-family-secondary);
}

.aboutHomeImage {
  width: auto;
  height: 475px;
}

.aboutHomeImageProfile {
  width: auto;
  height: 150px;
}

/* Valentines Image */
.valentinesImage {
  width: auto;
  height: 350px;
}

/* Mothers Day Image */
.mothersImage {
  width: auto;
  height: 350px;
}

/* Block Quote */
.blockquote {
  font-size: 80px;
}

/* Fun Fact Page */
.funFactHeadingOne {
  color: var(--secondary-color);
  font-size: 25px;
}

.funFactHeadingTwo {
  color: var(--secondary-color);
  font-size: 25px;
}

.funFactHeadingThree {
  color: var(--secondary-color);
  font-size: 25px;
}

.funFactSubHeadingOne {
  color: var(--secondary-color);
  font-size: 15px;
}

.funFactSubHeadingTwo {
  color: var(--secondary-color);
  font-size: 20px;
}

.funFactSubHeadingThree {
  color: var(--secondary-color);
  font-size: 20px;
}

/* Other CSS */
.centeredAbout {
  position: absolute;
  z-index: 2;
  top:40%;
  left: 15%;
  color: white;
  font-weight: bold;
  font-family: var(--font-family-secondary);
  font-size: 30px;
}

.formDiv {
  max-width: 90%
}

p {
  font-family: var(--font-family-primary);
  font-weight: 400;
  font-size: 20px;
  color: #343231
}

.contactInfoParagraph {
  font-size: 15px;
}

  .homePageText {
  max-width: 80%;
  overflow-wrap: break-word;
}

.photoCollage {
  max-width: 95%;
  margin-left: auto;
  margin-right: auto;
}



.logoPicture {
  width: auto;
  height: 65px;
}

.active {
  text-decoration: underline var(--primary-color) !important;
}

.dahlia {
  height: 150px;
  width: auto;
}

.cobaltBlue {
  height: 100px;
  width: 100px;
}


.flipText {
  transform: rotate(-90deg);
}

.flipTextOther {
  transform: rotate(-270deg);
}

.cdmx {
  height: 50px;
  width: auto;
}

.welcomeImage {
  max-width: 100%;
  max-height: 80%;
}

.center {
  z-index: 1;
  position: absolute;
  top: 2%;
  left: 7%;
  text-align: center;
  color: #f5f5f5;
  font-family: var(--font-family-primary);
  background-color: var(--primary-color);
  padding: 10px;
  border-radius: 5px;
}

.offCenter {
  position: absolute;
  top: 48%;
  left: 7%;
  text-align: center;
  font-family: var(--font-family-primary);
  color: #f5f5f5;
  background-color: var(--primary-color);
  padding: 10px;
  border-radius: 5px;
}

.centerAbout {
  position: absolute;
  top: 30%;
  left: 5%;
  text-align: center;
  color: #f5f5f5;
  font-family: var(--font-family-primary);
  background-color: var(--primary-color);
  padding: 10px;
  border-radius: 5px;
  transform: rotate(-25deg);
}

.offCenterAbout {
  position: absolute;
  top: 60%;
  left: 7%;
  text-align: center;
  font-family: var(--font-family-primary);
  color: #f5f5f5;
  background-color: var(--primary-color);
  padding: 10px;
  border-radius: 5px;
}



.gridBorder {
  border-bottom:1px solid var(--primary-color);
  border-right:1px solid var(--primary-color);
}

.gridBorderRight {
  border-bottom:1px solid var(--primary-color);
}

.gridBorderBottom {
  border-right:1px solid var(--primary-color);
}

.contactMeImage {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
}

/* Submit Button transition and hover changes */
.submit-btn {
  transition: background-color 0.3s ease, color 0.3s ease;
  /* Initial button styles */
  background-color: #ffffff; /* White background */
  color: var(--font-family-primary); /* Example color */
  border: 2px solid var(--tertiary-color-dark); /* Example border color */
  padding: 10px 20px; /* Padding inside the button */
  margin-top: 20px; /* Space above the button */
  margin-bottom: 20px; /* Space below the button */
}

.submit-btn:hover, .submit-btn:focus {
  background-color: var(--font-family-primary); /* Color changes on hover */
  color: #ffffff; /* Text color changes on hover */
  outline: none; /* Removes the outline on focus for cleaner look */
  border-color: #ffffff; /* Optional: change border color on hover */
}

/* Services Buttons */
.servicesButton {
  background-color: var(--tertiary-color); /* Primary color */
  color: white; /* Text color */
  border: 2px solid transparent; /* Transparent border */
  padding: 10px 20px; /* Padding around the text */
  font-size: 16px; /* Font size */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Cursor changes to a hand icon */
  transition: all 0.3s ease; /* Smooth transition for hover and focus effects */
  text-transform: uppercase; /* Optional: uppercase text */
  font-weight: bold; /* Optional: bold text */
}

.servicesButton:hover {
  background-color: white; /* Color on hover */
  color: var(--tertiary-color); /* Text color on hover */
  border: 2px solid var(--tertiary-color); /* Border color on hover */
}

.servicesButton:active {
  background-color: var(--tertiary-color); /* Color when clicked */
}

.servicesButton:focus {
  outline: none; /* Removes the default focus outline */
  box-shadow: 0 0 0 2px var(--tertiary-color); /* Adds a shadow for accessibility */
}

/* About Button */
.aboutButton {
  background-color: var(--tertiary-color); /* Primary color */
  color: white; /* Text color */
  border: 2px solid transparent; /* Transparent border */
  padding: 10px 20px; /* Padding around the text */
  font-size: 16px; /* Font size */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Cursor changes to a hand icon */
  transition: all 0.3s ease; /* Smooth transition for hover and focus effects */
  text-transform: uppercase; /* Optional: uppercase text */
  font-weight: bold; /* Optional: bold text */
}

.aboutButton:hover {
  background-color: white; /* Color on hover */
  color: var(--tertiary-color); /* Text color on hover */
  border: 2px solid var(--tertiary-color); /* Border color on hover */
}

.aboutButton:active {
  background-color: var(--tertiary-color); /* Color when clicked */
}

.aboutButton:focus {
  outline: none; /* Removes the default focus outline */
  box-shadow: 0 0 0 2px var(--tertiary-color); /* Adds a shadow for accessibility */
}

/* Services Intro */
.services-intro .image-container {
  position: relative;
  text-align: center;
}

.services-intro .services-image {
  width: 100%;
  height: auto;
  display: block;
}

.services-intro .text-overlay {
  position: absolute;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust for perfect center */
  color: white; /* Example text color */
  font-size: 2rem; /* Example font size */
  font-weight: bold;
  /* Additional styling as needed */
}


/*Visually Hidden for Desktop Browsers*/
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/* NavBar */
.navBar {
  border-radius: 5px 15px 200px 15px/15px 225px 15px 255px;
  border-bottom:solid 2px rgb(237, 70, 160, .8);
  background-color: white;
}

.NavBarLink {
  padding-right: 25px;
  padding-left: 25px;
  text-decoration: none;
  color: var(--primary-color);
  font-family: 'Barlow Condensed', sans-serif;
  font-weight: 500;
  font-size: 1.2rem;
}

.NavBarLink.active {
  text-decoration: underline;
}

.NavBarLink:hover {
  text-decoration: underline;
}

.NavBarLink:focus {
  outline: none;
}

/* Footer */
.footer {
  background-color: var(--primary-color);
  color: white;
  padding: 20px;
  text-align: center;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer-links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
}

.footerLink {
  margin: 0 10px;
  color: white;
  text-decoration: none;
}

.footerLink:hover {
  text-decoration: underline;
}

.logoPicture {
  width: 100px;
  height: auto;
}

/* Responsive Media Queries */
/* Very Large devices (desktops, 1210 and up) */
@media (min-width: 1210px) {
  .center, .offCenter, .centerAbout, .offCenterAbout {
    font-size: var(--font-size-large);
    font-weight: 600;
  }
  .formwidth {
    width: 50%;
  }
  .centered-title {
    font-size: 3rem; /* Adjusted for large devices */
  }
  .aboutHomeImage {
    height: 500px;
  }
  .aboutHomeImageProfile {
    height: 250px;
  }
  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .testimonial {
    font-size: 1.2rem; /* Larger font for readability */
    max-width: 800px; /* Limit the width for large screens */
    margin: auto; /* Center the testimonial */
  }
  .testimonialImage {
    width: 300px;
    height: 300px;
  }
  .servicesPhotos {
    height: 450px;
    width: auto;
  }
}

/* Large devices (desktops, 992px to 1210px) */
@media (min-width: 992px) and (max-width: 1209.98px) {
  .center, .offCenter, .centerAbout, .offCenterAbout {
    font-size: var(--font-size-large);
    font-weight: 600;
  }
  .formwidth {
    width: 50%;
  }
  .centered-title {
    font-size: 3rem; /* Adjusted for large devices */
  }
  .aboutHomeImage {
    height: 500px;
  }
  .aboutHomeImageProfile {
    height: 250px;
  }
  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .testimonial {
    font-size: 1.2rem; /* Larger font for readability */
    max-width: 800px; /* Limit the width for large screens */
    margin: auto; /* Center the testimonial */
  }
  .testimonialImage {
    width: 300px;
    height: 300px;
  }
  .servicesPhotos {
    height: 300px;
    width: 200px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .center, .offCenter, .centerAbout, .offCenterAbout {
    font-size: var(--font-size-medium);
  }
  .formwidth {
    width: 70%;
  }
  .centered-title {
    font-size: 2.5rem; /* Adjusted for medium devices */
  }
  .aboutHomeImage {
    width: 100%;
    height: auto;
  }
  .aboutHomeImageProfile {
    width: 100%;
    height: auto;
  }
  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .testimonial {
    font-size: 1.1rem; /* Slightly larger font */
  }
  .testimonialImage {
    width: 150px; /* Fixed size for larger screens */
    height: 150px;
  }
  .servicesPhotos {
    height: 225px;
    width: 150px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .center, .offCenter, .centerAbout, .offCenterAbout {
    font-size: var(--font-size-small);
  }
  .formwidth {
    width: 80%;
  }
  .centered-title {
    font-size: 2rem; /* Already adjusted for small devices */
  }
  .contactPage .row > div {
    margin-bottom: 20px;
  }
  .aboutHomeImage, .aboutHomeImageProfile {
    width: 100%; /* Adjust width for small devices */
    height: auto;
  }
  .aboutButton {
    padding: 10px 20px; /* Adjust button size for better touch */
  }
  .service-column-border {
    border-right: none;
  }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .center, .offCenter, .centerAbout, .offCenterAbout {
    font-size: var(--font-size-xsmall);
  }
  .formwidth {
    width: 90%;
  }
  .centered-title {
    font-size: 1.5rem; /* Already adjusted for extra small devices */
  }
  .aboutHomeImage, .aboutHomeImageProfile {
    height: auto;
  }
  .centered-title {
    /* Smaller font size on mobile devices */
    font-size: 1.5rem;
  }
  .contactPage .row > div {
    margin-bottom: 20px;
  }
  .aboutHomeImage, .aboutHomeImageProfile {
    width: 100%; /* Adjust width for extra small devices */
    height: auto;
  }
  .text-paragraph {
    font-size: 1rem; /* Smaller font size for better readability */
  }
  .aboutButton {
    padding: 8px 15px; /* Adjust button size for better touch */
    font-size: 0.9rem;
  }
  .testimonial {
    font-size: .9rem; /* Slightly smaller font */
  }
  .service-column-border {
    border-right: none;
  }
}